import React, { useEffect } from "react";

// Assets
import adLogoWide from "../assets/images/ancient-drinks-wide-logo.png";
import comingSoonRune from "../assets/images/coming-soon-rune.svg";
import storeLocatorPlaceholder from "../assets/images/store-locator.jpg";

// Borders
import borderLeft from "../assets/images/border-left.svg";
import borderTop from "../assets/images/border-top.svg";
import borderRight from "../assets/images/border-right.svg";
import borderBottom from "../assets/images/border-bottom.svg";

// Components
import Layout from "components/Global/Layout";
import AmazonButton from "components/Global/AmazonButton";

const StoreLocatorPage = () => {
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "//cdn.storerocket.io/widget.js";
		script.async = true;
		document.body.appendChild(script);

		script.onload = () => {
			if (window.StoreRocket) {
				window.StoreRocket.init({
					selector: "#storerocket-store-locator",
					account: "kDJ3zkL4mn",
				});
			}
		};

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<Layout
			pageName="store-locator"
			amazon="https://www.amazon.com/dp/B0CKRXJL6Q?maas=maas_adg_815CA62E3984F0A5167041CC3D3DDA58_afap_abs&ref_=aa_maas&tag=maas"
		>
			<div className="store-locator">
				<div className="store-locator-title-container">
					<h2>Store Locator</h2>
					<h3>(Coming Soon)</h3>
				</div>

				<div className="inner-store-locator-container">
					<div className="store-locator-title">
						<h3>Available nationwide on Amazon</h3>

						<AmazonButton to="https://www.amazon.com/dp/B0CKRXJL6Q?maas=maas_adg_815CA62E3984F0A5167041CC3D3DDA58_afap_abs&ref_=aa_maas&tag=maas" />
					</div>

					<div className="coming-soon-container">
						<div className="content-container">
							<div className="text-container">
								<img
									src={adLogoWide}
									alt="Ancient Drinks"
									className="coming-soon-logo"
								/>

								<div className="text-container-inner">
									<p className="golden-text">Rome wasn’t built in a day!</p>

									<p>
										The retail invasion's underway, and our best engineers have
										promised this modern tech will catch up soon!
									</p>
								</div>

								<img src={comingSoonRune} alt="" className="coming-soon-rune" />
							</div>

							<div className="image-container">
								<img src={storeLocatorPlaceholder} alt="Store Locator" />
							</div>
						</div>
					</div>

					{/* <div className="store-locator-border left">
						<img src={borderLeft} />
					</div>

					<div className="store-locator-border top">
						<img src={borderTop} />
					</div>

					<div className="store-locator-border right">
						<img src={borderRight} />
					</div>

					<div className="store-locator-border bottom">
						<img src={borderBottom} />
					</div> */}
					{/* <div
						id="storerocket-store-locator"
						className="store-locator-container"
					/> */}
				</div>
			</div>
		</Layout>
	);
};

export default StoreLocatorPage;
